import httpClient from './httpClient';

const getAllInvoices = (
  startDate: Date,
  endDate: Date,
  cancelController?: AbortController
) => {
  const startDateOffset = startDate.getTimezoneOffset();
  const endDateOffset = endDate.getTimezoneOffset();

  const offsetStartDate = new Date(
    startDate.getTime() - startDateOffset * 60 * 1000
  );
  const offsetEndDate = new Date(endDate.getTime() - endDateOffset * 60 * 1000);

  const formatedStartDate = offsetStartDate.toISOString().split('T')[0];
  const formatedEndDate = offsetEndDate.toISOString().split('T')[0];

  const params: {
    startDate: string;
    endDate: string;
  } = {
    startDate: formatedStartDate,
    endDate: formatedEndDate,
  };
  return httpClient.get(`invoice`, {
    params,
    signal: cancelController?.signal,
  });
};

const getInvoice = (invoiceId: string) =>
  httpClient.post(`invoice/${invoiceId}/download`);

export { getAllInvoices, getInvoice };
