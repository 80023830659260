
import { ref } from '@vue/reactivity';
import { defineComponent, watch } from '@vue/runtime-core';
import { ChevronRightIcon } from '@heroicons/vue/outline';
import { DocumentDownloadIcon, XCircleIcon } from '@heroicons/vue/solid';
import { Invoice } from '@/models';
import { getAllInvoices, getInvoice } from '@/api/invoices.api';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import store from '@/store';
import DatePickerGroup from '@/components/DatePickerGroup.vue';

export default defineComponent({
  name: 'billinginvoices',
  components: {
    SystemStatusBadge,
    ChevronRightIcon,
    DocumentDownloadIcon,
    XCircleIcon,
    DatePickerGroup,
  },
  setup() {
    const sharedState = store.state;

    const searchQuery = ref('');
    const invoices = ref({} as any);
    const invoicesFiltered = ref({} as any);
    const invoicesError = ref('');
    const invoicesLoading = ref(false);

    const startDate = ref(new Date());
    const endDate = ref(new Date());

    const invoiceCancelController = ref(new AbortController());

    const fetchInvoices = async () => {
      invoicesLoading.value = true;
      invoicesError.value = '';
      try {
        const response = await getAllInvoices(
          startDate.value as Date,
          endDate.value as Date,
          invoiceCancelController.value
        );
        invoices.value = response.data.invoices;
      } catch (e) {
        invoices.value = [];
        invoicesError.value = `Error fetching invoices`;
      }
      invoicesLoading.value = false;
    };

    const downloadInvoice = async (invoice: Invoice) => {
      invoicesLoading.value = true;
      invoicesError.value = '';
      try {
        const response = await getInvoice(invoice.id);

        const anchor = document.createElement('a');
        anchor.href =
          'data:text/csv;charset=utf-8,' + encodeURIComponent(response.data);
        anchor.target = '_blank';
        anchor.download = `gordian_invoice_${invoice.invoiceNumber}_${invoice.startDate}_${invoice.endDate}.csv`;
        anchor.click();
      } catch (e) {
        invoicesError.value = `Error downloading invoice`;
      }
      invoicesLoading.value = false;
    };

    const abortRequests = () => {
      invoiceCancelController.value.abort();
      invoiceCancelController.value = new AbortController();
    };

    watch(
      () => searchQuery.value || invoices.value,
      () => {
        const search = searchQuery.value.toLowerCase().trim();
        invoicesFiltered.value =
          !search || !invoices.value.length
            ? invoices.value
            : invoices.value?.filter(
                (invoice: Invoice) =>
                  invoice.id?.toString().toLowerCase().includes(search) ||
                  invoice.invoiceNumber?.toLowerCase().includes(search) ||
                  invoice.invoiceSentDate?.toLowerCase().includes(search) ||
                  invoice.startDate?.toLowerCase().includes(search) ||
                  invoice.endDate?.toLowerCase().includes(search)
              );
      }
    );

    watch(
      () => sharedState.integration?.organization?.name,
      () => {
        if (sharedState.integration?.organization?.name) {
          fetchInvoices();
        }
      },
      { immediate: true }
    );

    const onDateChange = (data: { startDate: Date; endDate: Date }) => {
      startDate.value = data.startDate;
      endDate.value = data.endDate;

      abortRequests();
      fetchInvoices();
    };

    return {
      searchQuery,
      invoices,
      invoicesFiltered,
      invoicesLoading,
      invoicesError,
      downloadInvoice,
      startDate,
      endDate,
      onDateChange,
    };
  },
});
